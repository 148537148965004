@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://use.typekit.net/ixf7tfb.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Set the site color */
body {
  background-color: #fcf6f8;
}

/* Sets conditions for different tags */
@layer base {
  p {
    @apply max-w-prose leading-relaxed my-1;
  }

  /* Allows margin between p tags but not at the top or bottom of a sequence of p tags */
  p:first-child {
    @apply mt-0;
  }

  p:last-child {
    @apply mb-0;
  }

  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p,
  h6 + p {
    @apply mt-0;
  }

  /* apply link styling for a tags */
  p a {
    @apply underline decoration-blue-700 decoration-2 underline-offset-2 uppercase tracking-tight font-semibold hover:text-blue-700;
  }

  blockquote {
    @apply border-l-2 border-current pl-4 my-8;
  }

  input[type='email'] {
    @apply drop-shadow-md bg-white p-4 rounded-md text-center;
  }
}

/* Not yet in use, but for upcoming graphic design and discography pages */
@layer components {
  .gallery {
    @apply flex flex-row overflow-y-visible overflow-x-scroll items-center snap-x snap-mandatory no-scrollbars -my-8 py-16 px-[5vw] gap-[5vw] md:gap-8 [&>*]:shrink-0 [&>*]:snap-center [&>*]:block [&>*]:rounded [&>*]:shadow-xl [&>*]:max-h-[40vh] sm:[&>*]:max-h-[50vh] [&>*]:max-w-[90%];
  }

  .button {
    @apply inline-block shadow-md bg-black py-4 px-12 text-white rounded-full no-underline transition-all hover:shadow-lg hover:scale-[1.02];
  }
}

/* Custom classes to tailwinds */
@layer utilities {
  .h2 {
    @apply text-lg uppercase font-semibold tracking-widest mb-4;
  }

  .h3 {
    @apply text-sm uppercase tracking-widest opacity-40 mb-2;
  }

  .picture {
    @apply rounded shadow-xl my-16;
  }

  .no-scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .no-scrollbars::-webkit-scrollbar {
    display: none;
  }
}

/* AHEAD: Custom classes for displaying coding projects */
/* container for each project; the hover conditions make sure that the title and logos will be visible while hovering, and blurs/dims/opaques the picture */
.container {
  position: relative;
  text-align: center;
  color: white;
  margin-top: 10px;
  border: black;
  border-radius: 10px;
  border-width: thick;
}

.container:hover .github-logo {
  opacity: 1;
}

.container:hover .project-image {
  filter: blur(1px) brightness(60%) opacity(0.5);
}

.container:hover .project-title {
  opacity: 1;
}

.container:hover .website-logo {
  opacity: 1;
}

/* Placement for the logo */
.github-logo {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Make sure the logo is hidden before hovering */
}

/* Make sure the logo is visible when hovering */
.github-logo:hover {
  opacity: 1;
}

/* placement and sizing for the image. */
.project-image {
  position: relative;
  max-width: 300px;
  border: black;
  border-radius: 10px;
  border-width: thick;
}

/* Placement and sizing for the title */
.project-title {
  font-size: 25px;
  font-weight: bold;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Make sure the logo is hidden before hovering */
}

/* Make sure the title is visible when hovering */
.project-title:hover {
  opacity: 1;
}

/* Placement for the logo */
.website-logo {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Make sure the logo is hidden before hovering */
}

/* Make sure the logo is visible when hovering */
.website-logo:hover {
  opacity: 1;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
	0%, 100% { opacity: 1; }
	50% { opacity: 0; }
  }
  
  .animate-blink {
	animation: blink 1s infinite;
  }
